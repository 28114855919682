import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  loading: false,
  postRate: null,
  error: null,
};

export const postRateAsync = createAsyncThunk(
  "postRate",
  async (data) => {
    const response = await axios.post(
      `${urls.localURL}/review/`,
      data,
      headerRequest()
    );
    return response.data;
  }
);

export const postRateSlice = createSlice({
  name: "postRate",
  initialState,
  reducers: {
    postRateReset: (state) => {
      state.loading = false;
      state.postRate = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postRateAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(postRateAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.postRate = action.payload;
      })
      .addCase(postRateAsync.rejected, (state) => {
        state.loading = false;
        state.postRate = null;
        state.error = null;
      });
  },
});

export const postRateState = (state) => state.postRateSlice;

export const { postRateReset } = postRateSlice.actions;
export default postRateSlice.reducer;
