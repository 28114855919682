import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  getProfile: null,
  error: null,
  length: 0,
  loading: false,
};

export const getProfileAsync = createAsyncThunk(
  "account/getProfileSlice",
  async () => {
    const response = await axios.get(`${urls.localURL}/profile/`, headerRequest())
    return response.data;
  }
);

export const getProfileSlice = createSlice({
  name: "getProfileSlice",
  initialState,
  reducers: {
    getProfileReset: (state) => {
        state.getProfile=null
        state.error=null
        state.status = 0
        state.length = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfileAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getProfile = action.payload?.[0];
        state.length = action.payload.length
      })
      .addCase(getProfileAsync.rejected, (state) => {
        state.loading = false;
        state.getProfile=null
        state.error=null
        state.length = 0
      });
  },
});

export const { getProfileReset } = getProfileSlice.actions;

export const getProfileState = (state) => state.getProfileSlice;


export default getProfileSlice.reducer;
