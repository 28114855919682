import React from "react";
import { Layout, theme, message } from "antd";
import SideMenu from "../../Common/SideMenu";
import { useSelector, useDispatch } from "react-redux";
import { getSocialMediaAsync } from "../ProfileDetails/SocialMedia/Slice/getSocialMediaSlice";
import { getSocialIconAsync } from "../ProfileDetails/SocialMedia/Slice/getSocialIconSlice";
import { getProfileAsync } from "../ProfileDetails/Account/Slice/getProfileSlice";
import { updateUserState } from "../ProfileDetails/Account/Slice/updateUserSlice";
import {
  updateProfileReset,
  updateProfileState,
} from "../ProfileDetails/Account/Slice/updateProfileSlice";
import { Outlet } from "react-router-dom";
const { Header, Content } = Layout;

const Homepage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const dispatch = useDispatch();
  const { updateUser } = useSelector(updateUserState);
  const { updateProfile } = useSelector(updateProfileState);

  React.useEffect(() => {
    dispatch(getSocialMediaAsync());
    dispatch(getSocialIconAsync());
    dispatch(getProfileAsync());
  }, [dispatch, updateUser, updateProfile]);

  React.useEffect(() => {
    if (updateProfile) {
      messageApi
        .open({
          type: "loading",
          content: "Action in progress..",
          duration: 1.5,
        })
        .then(() => message.success("Submited", 2.5));
      dispatch(updateProfileReset());
    }
  }, [dispatch, updateProfile, messageApi]);

  return (
    <Layout>
      {contextHolder}
      <SideMenu />
      <Header
        style={{
          padding: 0,
          background: colorBgContainer,
        }}
      />
      <Content
        style={{
          margin: "24px 16px 0 16px",
          padding: 24,
          height: "100vh",
          background: colorBgContainer,
        }}
      >
        <Outlet />
      </Content>
    </Layout>
  );
};

export default Homepage;
