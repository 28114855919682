import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  loading: false,
  getGroupLectures: [],
  error: null,
};

export const getGroupLecturesAsync = createAsyncThunk("getGroupLectures", async (data) => {
  const { course, school } = data
  const response = await axios.get(
    `${urls.localURL}/group_lecture/?course__slug=${course}&course__school__slug=${school}&action=curriculum`,
    headerRequest()
  );
  return response.data;
});

export const getGroupLecturesSlice = createSlice({
  name: "getGroupLectures",
  initialState,
  reducers: {
    getGroupLecturesReset: (state) => {
      state.loading = false;
      state.getGroupLectures = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGroupLecturesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGroupLecturesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getGroupLectures = action.payload;
      })
      .addCase(getGroupLecturesAsync.rejected, (state) => {
        state.loading = false;
        state.getGroupLectures = null;
        state.error = null;
      });
  },
});

export const getGroupLecturesState = (state) => state.getGroupLecturesSlice;

export const { getGroupLecturesReset } = getGroupLecturesSlice.actions;
export default getGroupLecturesSlice.reducer;
