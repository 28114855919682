import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import urls from "../../../../app/requests/urls";
import { headerRequest } from "../../../../app/requests/headerRequest";

const initialState = {
  postLogout: null,
  error: null,
  loading: false,
};

export const postLogoutAsync = createAsyncThunk(
  "auth/postLogoutSlice",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${urls.localURL}/rest-auth/logout/`,
        null,
        headerRequest()
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const postLogoutSlice = createSlice({
  name: "postLogoutSlice",
  initialState,
  reducers: {
    postLogoutReset: (state) => {
      state.postLogout = null;
      state.error = null;
      state.status = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postLogoutAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(postLogoutAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.postLogout = action.payload;
        state.status = 200;
        state.error = null;
      })
      .addCase(postLogoutAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { postLogoutReset } = postLogoutSlice.actions;

export const postLogoutState = (state) => state.postLogoutSlice;

export default postLogoutSlice.reducer;
