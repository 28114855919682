import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { headerRequest } from "../../../app/requests/headerRequest";
import urls from "../../../app/requests/urls";

const initialState = {
  postChangePassword: null,
  error: null,
  loading: false,
};

export const postChangePasswordAsync = createAsyncThunk(
  "auth/postChangePasswordSlice",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${urls.localURL}/rest-auth/password/change/`, values, headerRequest())
    return response.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
    
  }
);

export const postChangePasswordSlice = createSlice({
  name: "postChangePasswordSlice",
  initialState,
  reducers: {
    postChangePasswordReset: (state) => {
        state.postChangePassword=null
        state.error=null
        state.status = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postChangePasswordAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(postChangePasswordAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.postChangePassword = action.payload;
        state.error=null
      })
      .addCase(postChangePasswordAsync.rejected, (state, action) => {
        state.loading = false;
        state.error=action.payload
      });
  },
});

export const { postChangePasswordReset } = postChangePasswordSlice.actions;

export const postChangePasswordState = (state) => state.postChangePasswordSlice;


export default postChangePasswordSlice.reducer;
