import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { headerRequest } from "../../../app/requests/headerRequest";
import urls from "../../../app/requests/urls";

const initialState = {
  deleteUser: null,
  error: null,
  loading: false,
};

export const deleteUserAsync = createAsyncThunk(
  "auth/deleteUserSlice",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${urls.localURL}/user/${id}/`, headerRequest())
    return response.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
    
  }
);

export const deleteUserSlice = createSlice({
  name: "deleteUserSlice",
  initialState,
  reducers: {
    deleteUserReset: (state) => {
        state.deleteUser=null
        state.error=null
        state.status = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteUserAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteUser = 204;
        state.error=null
      })
      .addCase(deleteUserAsync.rejected, (state, action) => {
        state.loading = false;
        state.error=action.payload
        state.status=0
      });
  },
});

export const { deleteUserReset } = deleteUserSlice.actions;

export const deleteUserState = (state) => state.deleteUserSlice;


export default deleteUserSlice.reducer;
