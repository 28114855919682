import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import urls from "../../../../app/requests/urls";
import { headerRequest } from "../../../../app/requests/headerRequest";

const initialState = {
  loading: false,
  getCalendar: [],
  error: null,
};

export const getCalendarAsync = createAsyncThunk("getCalendar", async (id) => {
  const response = await axios.get(
    `${urls.localURL}/calendar/?school=${id}&action=curriculum`,
    headerRequest()
  );
  return response.data;
});

export const getCalendarSlice = createSlice({
  name: "getCalendar",
  initialState,
  reducers: {
    getCalendarReset: (state) => {
      state.loading = false;
      state.getCalendar = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCalendarAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCalendarAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getCalendar = action.payload;
      })
      .addCase(getCalendarAsync.rejected, (state) => {
        state.loading = false;
        state.getCalendar = null;
        state.error = null;
      });
  },
});

export const getCalendarState = (state) => state.getCalendarSlice;

export const { getCalendarReset } = getCalendarSlice.actions;
export default getCalendarSlice.reducer;
