export const home = "/";

export const profile = "profile";

export const manageAccount = "manage-account";

export const logout = "logout";

export const page404 = "/404"

export const manage = "/manage";

export const signup = "signup";

export const forgot = "forgot-password";

export const activate = "activate-account";

export const terms = "terms-conditions";

export const passwordReset = "password-reset-confirm/:uid/:token";

export const login = "login";


export const oneSchool = "/education";
export const courses = ":education/schools/:school/courses";
export const oneCourse = ":education/schools/:school/courses/:course";
export const oneLecture = "/education/:education/schools/:school/courses/:course/lectures/:lecture";

export const oneEducation = "/education/:education";
export const schoolsEducation = "/education/:education/schools/:school";