import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import urls from "../../../../app/requests/urls";
import { headerRequest } from "../../../../app/requests/headerRequest";

const initialState = {
  postForgotPassword: null,
  error: null,
  loading: false,
};

export const postForgotPasswordAsync = createAsyncThunk(
  "auth/postForgotPasswordSlice",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${urls.localURL}/rest-auth/password/reset/`, values, headerRequest())
    return response.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
    
  }
);

export const postForgotPasswordSlice = createSlice({
  name: "postForgotPasswordSlice",
  initialState,
  reducers: {
    postForgotPasswordReset: (state) => {
        state.postForgotPassword=null
        state.error=null
        state.status = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postForgotPasswordAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(postForgotPasswordAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.postForgotPassword = action.payload;
        state.error=null
      })
      .addCase(postForgotPasswordAsync.rejected, (state, action) => {
        state.loading = false;
        state.error=action.payload
      });
  },
});

export const { postForgotPasswordReset } = postForgotPasswordSlice.actions;

export const postForgotPasswordState = (state) => state.postForgotPasswordSlice;


export default postForgotPasswordSlice.reducer;
