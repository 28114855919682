import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  getSocialMedia: null,
  error: null,
  length: 0,
  loading: false,
};

export const getSocialMediaAsync = createAsyncThunk(
  "account/getSocialMediaSlice",
  async () => {
    const response = await axios.get(`${urls.localURL}/social-profile/`, headerRequest())
    return response.data;
  }
);

export const getSocialMediaSlice = createSlice({
  name: "getSocialMediaSlice",
  initialState,
  reducers: {
    getSocialMediaReset: (state) => {
        state.getSocialMedia=null
        state.error=null
        state.status = 0
        state.length = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSocialMediaAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSocialMediaAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getSocialMedia = action.payload;
        state.length = action.payload.length
      })
      .addCase(getSocialMediaAsync.rejected, (state) => {
        state.loading = false;
        state.getSocialMedia=null
        state.error=null
        state.length = 0
      });
  },
});

export const { getSocialMediaReset } = getSocialMediaSlice.actions;

export const getSocialMediaState = (state) => state.getSocialMediaSlice;


export default getSocialMediaSlice.reducer;
