import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import urls from "../../../../app/requests/urls";
import { headerRequest } from "../../../../app/requests/headerRequest";

const initialState = {
  postLogin: null,
  error: null,
  loading: false,
};

export const postLoginAsync = createAsyncThunk(
  "auth/postLoginSlice",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${urls.localURL}/rest-auth/login/`, values, headerRequest())
    return response.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
    
  }
);

export const postLoginSlice = createSlice({
  name: "postLoginSlice",
  initialState,
  reducers: {
    postLoginReset: (state) => {
        state.postLogin=null
        state.error=null
        state.status = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postLoginAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(postLoginAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.postLogin = action.payload;
        state.error=null
      })
      .addCase(postLoginAsync.rejected, (state, action) => {
        state.loading = false;
        state.error=action.payload
      });
  },
});

export const { postLoginReset } = postLoginSlice.actions;

export const postLoginState = (state) => state.postLoginSlice;


export default postLoginSlice.reducer;
