import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import urls from "../../../../app/requests/urls";
import { headerRequest } from "../../../../app/requests/headerRequest";

const initialState = {
  loading: false,
  getPresentations: [],
  error: null,
};

export const getPresentationsAsync = createAsyncThunk("getPresentations", async (id) => {
  const response = await axios.get(
    `${urls.localURL}/presentation/?school=${id}`,
    headerRequest()
  );
  return response.data;
});

export const getPresentationsSlice = createSlice({
  name: "getPresentations",
  initialState,
  reducers: {
    getPresentationsReset: (state) => {
      state.loading = false;
      state.getPresentations = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPresentationsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPresentationsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getPresentations = action.payload;
      })
      .addCase(getPresentationsAsync.rejected, (state) => {
        state.loading = false;
        state.getPresentations = null;
        state.error = null;
      });
  },
});

export const getPresentationsState = (state) => state.getPresentationsSlice;

export const { getPresentationsReset } = getPresentationsSlice.actions;
export default getPresentationsSlice.reducer;
