import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  loading: false,
  getOneCourse: [],
  error: null,
};

export const getOneCourseAsync = createAsyncThunk("getOneCourse", async (course) => {
  const response = await axios.get(
    `${urls.localURL}/courses/${course}/?curriculum=true`,
    headerRequest()
  );
  return response.data;
});

export const getOneCourseSlice = createSlice({
  name: "getOneCourse",
  initialState,
  reducers: {
    getOneCourseReset: (state) => {
      state.loading = false;
      state.getOneCourse = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOneCourseAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOneCourseAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getOneCourse = action.payload;
      })
      .addCase(getOneCourseAsync.rejected, (state, action) => {
        state.loading = false;
        state.getOneCourse = null;
        state.error = action.error.message;
      });
  },
});

export const getOneCourseState = (state) => state.getOneCourseSlice;

export const { getOneCourseReset } = getOneCourseSlice.actions;
export default getOneCourseSlice.reducer;
