import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  loading: false,
  getOneLecture: null,
  error: null,
};

export const getOneLectureAsync = createAsyncThunk("getOneLecture", async (data) => {
  const { course, lecture } = data
  const response = await axios.get(
    `${urls.localURL}/lectures/${lecture}/?group__course__slug=${course}&action=curriculum`,
    headerRequest()
  );
  return response.data;
});

export const getOneLectureSlice = createSlice({
  name: "getOneLecture",
  initialState,
  reducers: {
    getOneLectureReset: (state) => {
      state.loading = false;
      state.getOneLecture = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOneLectureAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOneLectureAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getOneLecture = action.payload;
      })
      .addCase(getOneLectureAsync.rejected, (state, action) => {
        state.loading = false;
        state.getOneLecture = null;
        state.error = action.error.message;
      });
  },
});

export const getOneLectureState = (state) => state.getOneLectureSlice;

export const { getOneLectureReset } = getOneLectureSlice.actions;
export default getOneLectureSlice.reducer;
