import React from "react";
import { Menu } from "antd";
import {
  EyeOutlined,
  IssuesCloseOutlined,
  BookOutlined,
  SettingOutlined,
  LogoutOutlined,
  ScheduleOutlined,
  BgColorsOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserState } from "../features/ProfileDetails/Account/Slice/getUserSlice";
import { getProfileState } from "../features/ProfileDetails/Account/Slice/getProfileSlice";
import { home, logout, manageAccount, profile } from "../app/URL/RouteUrl";
import getItem from "./getItem";
import { changeTheme } from "../app/Slice/themeSlice";
import { themeState } from "../app/Slice/themeSlice";

const MenuSide = () => {
  const { pathname } = useLocation();
  const { getUser } = useSelector(getUserState);
  const { getProfile } = useSelector(getProfileState);
  const dispatch = useDispatch();
  const { isDarkMode } = useSelector(themeState);
  const items = [
    getItem(<Link to={home}>Schools</Link>, "/", <BookOutlined />),
    getItem(
      <Link to={`/${profile}`}>Profile</Link>,
      "/profile",
      <EyeOutlined />
    ),
    getItem(
      <Link to={`/${manageAccount}`}>Manage</Link>,
      "/manage",
      <IssuesCloseOutlined />
    ),
    getProfile?.auth &&
      getItem(
        getProfile?.auth?.super_admin ? (
          <Link to="/dashboard/" reloadDocument>
            Admin
          </Link>
        ) : (
          "Admin"
        ),
        "admin",
        <SettingOutlined />,
        childItems(getUser)
      ),
    getItem(
      <Link to={`#`} onClick={() => dispatch(changeTheme())}>
        {isDarkMode ? "Light " : "Dark "}Mode
      </Link>,
      "theme",
      <BgColorsOutlined />
    ),
    getItem(
      <Link to={`/${logout}`}>Logout</Link>,
      "logout",
      <LogoutOutlined />
    ),
  ];

  return (
    getUser && (
      <Menu
        mode="inline"
        defaultSelectedKeys={[pathname]}
        defaultOpenKeys={[pathname]}
        items={items}
      />
    )
  );
};

export default MenuSide;

const childItems = (getUser) => {
  return getUser?.schools_admin?.map((element) =>
    getItem(
      <Link
        to={`/dashboard/schools/${element?.education_id}/school/${element?.slug}`}
        reloadDocument
      >
        {element?.title}
      </Link>,
      element?.title,
      <ScheduleOutlined />
    )
  );
};
