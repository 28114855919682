import React from "react";
import { theme, Layout, Space } from "antd";
import MenuSide from "./MenuSide";
import Profile from "./Profile";
const { Sider } = Layout;

const SideMenu = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      style={{
        background: colorBgContainer,
        padding: "80px 0px",
      }}
      width={205}
    >
      <Space direction="vertical" size={40}>
        <Profile  />
        <MenuSide />
      </Space>
    </Sider>
  );
};

export default SideMenu;
