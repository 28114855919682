import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import urls from "../../../app/requests/urls";
import { headerRequest } from "../../../app/requests/headerRequest";

const initialState = {
  loading: false,
  getOneEducation: [],
  error: null,
};

export const getOneEducationAsync = createAsyncThunk("getOneEducation", async (education) => {
  const response = await axios.get(
    `${urls.localURL}/education/${education}/`,
    headerRequest()
  );
  return response.data;
});

export const getOneEducationSlice = createSlice({
  name: "getOneEducation",
  initialState,
  reducers: {
    getOneEducationReset: (state) => {
      state.loading = false;
      state.getOneEducation = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOneEducationAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOneEducationAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getOneEducation = action.payload;
      })
      .addCase(getOneEducationAsync.rejected, (state) => {
        state.loading = false;
        state.getOneEducation = null;
        state.error = null;
      });
  },
});

export const getOneEducationState = (state) => state.getOneEducationSlice;

export const { getOneEducationReset } = getOneEducationSlice.actions;
export default getOneEducationSlice.reducer;
