import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  postAttendance: null,
  error: null,
  loading: false,
};

export const postAttendanceAsync = createAsyncThunk(
  "account/postAttendanceSlice",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${urls.localURL}/attendance/`, values, headerRequest())
    return response.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
    
  }
);

export const postAttendanceSlice = createSlice({
  name: "postAttendanceSlice",
  initialState,
  reducers: {
    postAttendanceReset: (state) => {
        state.postAttendance=null
        state.error=null
        state.status = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postAttendanceAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(postAttendanceAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.postAttendance = action.payload;
        state.error=null
      })
      .addCase(postAttendanceAsync.rejected, (state, action) => {
        state.loading = false;
        state.error=action.payload
      });
  },
});

export const { postAttendanceReset } = postAttendanceSlice.actions;

export const postAttendanceState = (state) => state.postAttendanceSlice;


export default postAttendanceSlice.reducer;
