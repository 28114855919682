import React from "react";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import Footer from "../Common/Footer";
import { ConfigProvider, theme } from "antd";
import { router } from "./router";
import "../fonts/CamingoCode/CamingoCode-Regular.ttf";
import { useSelector } from "react-redux";
import { themeState } from "./Slice/themeSlice";

function App() {
  const { defaultAlgorithm, darkAlgorithm } = theme;

  const { isDarkMode } = useSelector(themeState);

 
  

  return (
    <div className="App">
      <ConfigProvider
        theme={{
          algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
          token: {
            fontFamily: "CamingoCode",
            colorPrimary: "#9EB689",
          },
        }}
      >
        <RouterProvider router={router} />

        <Footer />
      </ConfigProvider>
    </div>
  );
}

export default App;
