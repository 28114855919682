import React from "react";
import { Typography, Button, Space } from "antd";
import { LinkedinOutlined, GithubOutlined } from "@ant-design/icons";
import { getProfileState } from "../features/ProfileDetails/Account/Slice/getProfileSlice";
import { useSelector } from "react-redux";
import { getUserState } from "../features/ProfileDetails/Account/Slice/getUserSlice";
import { getSocialMediaState } from "../features/ProfileDetails/SocialMedia/Slice/getSocialMediaSlice";
import { Link } from "react-router-dom";
import { profile } from "../app/URL/RouteUrl";
const { Title, Text } = Typography;

const Profile = () => {
  const { getProfile } = useSelector(getProfileState);
  const { getUser } = useSelector(getUserState);
  const { getSocialMedia } = useSelector(getSocialMediaState);

  return (
    <div>
      <img
        src={
          getProfile?.photo ||
          `${process.env.PUBLIC_URL}/img/default_profile.png`
        }
        alt="profile-img"
        className="profile-img"
      />
        <Space direction="vertical" size="middle" align="center" style={{margin:10}}>
          <Title
            level={3}
          >{`${getUser?.first_name} ${getUser?.last_name}`}</Title>
          <Text strong>{getUser?.username}</Text>
          <Text>{getUser?.email}</Text>

          <Space>
            {getSocialMedia?.map((value) => (
              <Button
                key={value?.id}
                href={value?.link}
                size="large"
                icon={
                  value?.social_media_name === "github" ? (
                    <GithubOutlined />
                  ) : (
                    <LinkedinOutlined />
                  )
                }
              />
            ))}
          </Space>

          {getSocialMedia?.length <= 0 && (
            <Link to={`/${profile}?tab=social-media`}>
              <Button type="primary">Add Social Profile</Button>
            </Link>
          )}
        </Space>
    </div>
  );
};

export default Profile;
