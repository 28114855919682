import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  loading: false,
  getRate: null,
  error: null,
};

export const getRateAsync = createAsyncThunk("getRate", async (data) => {
  const {id, course } = data
  const response = await axios.get(
    `${urls.localURL}/review/?course=${course}&student__user__user__id=${id}`,
    headerRequest()
  );
  return response.data;
});

export const getRateSlice = createSlice({
  name: "getRate",
  initialState,
  reducers: {
    getRateReset: (state) => {
      state.loading = false;
      state.getRate = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRateAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRateAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getRate = action.payload?.[0];
      })
      .addCase(getRateAsync.rejected, (state) => {
        state.loading = false;
        state.getRate = null;
        state.error = null;
      });
  },
});

export const getRateState = (state) => state.getRateSlice;

export const { getRateReset } = getRateSlice.actions;
export default getRateSlice.reducer;
