import React from "react";
import { Layout, Typography } from "antd";
import { useSelector } from "react-redux";
import { themeState } from "../../app/Slice/themeSlice";
import { Outlet, useLocation } from "react-router-dom";

const { Title } = Typography;
function Auth({ children, title, login = false }) {
  const { pathname } = useLocation();
  const isTerm = pathname === "/manage/terms-conditions";
  const { isDarkMode } = useSelector(themeState);
  return (
    <Layout style={{ minHeight: isTerm ? "100%" : "100vh" }}>
      <div
        className={`auth-form ${login || isTerm ? "" : "auth-wrapper-form"}`}
      >
        <img
          src={`${process.env.PUBLIC_URL}/img/logo-${isDarkMode ? "w" : "b"}.png`}
          alt="logo-matrixmaster"
          className="auth-login"
        />
        <Title level={2} style={{ textAlign: "center" }}>
          {title}
        </Title>
        {children}

        <Outlet />
      </div>
    </Layout>
  );
}

export default Auth;
