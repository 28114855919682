import React from "react";
import { Navigate } from "react-router-dom";
import { home } from "../URL/RouteUrl";

const ProtectedRouteForLogin = ({ children }) => {
  if (localStorage.getItem("token")) {
    return <Navigate to={home} replace />;
  }
  return children;
};
export default ProtectedRouteForLogin;
