import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  getUser: null,
  error: null,
  length: 0,
  loading: false,
};

export const getUserAsync = createAsyncThunk(
  "account/getUserSlice",
  async (x, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${urls.localURL}/user/?action=curriculum`,
        headerRequest()
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const getUserSlice = createSlice({
  name: "getUserSlice",
  initialState,
  reducers: {
    getUserReset: (state) => {
      state.getUser = null;
      state.error = null;
      state.status = 0;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getUser = action.payload?.[0];
        state.length = action.payload.length;
      })
      .addCase(getUserAsync.rejected, (state, action) => {
        state.loading = false;
        state.status = action?.payload?.status;
        state.error = action?.payload?.data?.detail;
      });
  },
});

export const { getUserReset } = getUserSlice.actions;

export const getUserState = (state) => state.getUserSlice;

export default getUserSlice.reducer;
