import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  postSocialMedia: null,
  error: null,
  loading: false,
};

export const postSocialMediaAsync = createAsyncThunk(
  "account/postSocialMediaSlice",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${urls.localURL}/social-profile/`, values, headerRequest())
    return response.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
    
  }
);

export const postSocialMediaSlice = createSlice({
  name: "postSocialMediaSlice",
  initialState,
  reducers: {
    postSocialMediaReset: (state) => {
        state.postSocialMedia=null
        state.error=null
        state.status = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postSocialMediaAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(postSocialMediaAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.postSocialMedia = action.payload;
        state.error=null
      })
      .addCase(postSocialMediaAsync.rejected, (state, action) => {
        state.loading = false;
        state.error=action.payload
      });
  },
});

export const { postSocialMediaReset } = postSocialMediaSlice.actions;

export const postSocialMediaState = (state) => state.postSocialMediaSlice;


export default postSocialMediaSlice.reducer;
