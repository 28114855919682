import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  loading: false,
  postComment: null,
  error: null,
};

export const postCommentAsync = createAsyncThunk(
  "postComment",
  async (data) => {
    const response = await axios.post(
      `${urls.localURL}/comments/`,
      data,
      headerRequest()
    );
    return response.data;
  }
);

export const postCommentSlice = createSlice({
  name: "postComment",
  initialState,
  reducers: {
    postCommentReset: (state) => {
      state.loading = false;
      state.postComment = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCommentAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(postCommentAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.postComment = action.payload;
      })
      .addCase(postCommentAsync.rejected, (state) => {
        state.loading = false;
        state.postComment = null;
        state.error = null;
      });
  },
});

export const postCommentState = (state) => state.postCommentSlice;

export const { postCommentReset } = postCommentSlice.actions;
export default postCommentSlice.reducer;
