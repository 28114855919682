import { Button, Layout, Result } from "antd";
import { Link } from "react-router-dom";
import { login } from "../../app/URL/RouteUrl";

const Page404 = () => (
  <Layout style={{ minHeight: "100vh" }}>
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Link to={`/${login}`}>
          <Button type="primary">Back Home</Button>
        </Link>
      }
    />
  </Layout>
);
export default Page404;
