import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import urls from "../../../app/requests/urls";
import { headerRequest } from "../../../app/requests/headerRequest";

const initialState = {
  loading: false,
  getSchool: [],
  error: null,
};

export const getSchoolAsync = createAsyncThunk("getSchool", async () => {
  const response = await axios.get(
    `${urls.localURL}/school/?profile=true/`,
    headerRequest()
  );
  return response.data;
});

export const getSchoolSlice = createSlice({
  name: "getSchool",
  initialState,
  reducers: {
    getSchoolReset: (state) => {
      state.loading = false;
      state.getSchool = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSchoolAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSchoolAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getSchool = action.payload;
      })
      .addCase(getSchoolAsync.rejected, (state) => {
        state.loading = false;
        state.getSchool = null;
        state.error = null;
      });
  },
});

export const getSchoolState = (state) => state.getSchoolSlice;

export const { getSchoolReset } = getSchoolSlice.actions;
export default getSchoolSlice.reducer;
