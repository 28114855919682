import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import urls from "../../../../app/requests/urls";
import { headerRequest } from "../../../../app/requests/headerRequest";

const initialState = {
  loading: false,
  getAttendance: [],
  error: null,
};

export const getAttendanceAsync = createAsyncThunk("getAttendance", async (id) => {
  const response = await axios.get(
    `${urls.localURL}/attendance/?student__user=${id}`,
    headerRequest()
  );
  return response.data;
});

export const getAttendanceSlice = createSlice({
  name: "getAttendance",
  initialState,
  reducers: {
    getAttendanceReset: (state) => {
      state.loading = false;
      state.getAttendance = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAttendanceAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAttendanceAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getAttendance = action.payload;
      })
      .addCase(getAttendanceAsync.rejected, (state) => {
        state.loading = false;
        state.getAttendance = null;
        state.error = null;
      });
  },
});

export const getAttendanceState = (state) => state.getAttendanceSlice;

export const { getAttendanceReset } = getAttendanceSlice.actions;
export default getAttendanceSlice.reducer;
