import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  updateUser: null,
  error: null,
  length: 0,
  loading: false,
};

export const updateUserAsync = createAsyncThunk(
  "account/updateUserSlice",
  async (userData, { rejectWithValue }) => {
    const { id, values } = userData
    try {
      const response = await axios.put(`${urls.localURL}/user/${id}/`, values, headerRequest())
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
);

export const updateUserSlice = createSlice({
  name: "updateUserSlice",
  initialState,
  reducers: {
    updateUserReset: (state) => {
        state.updateUser=null
        state.error=null
        state.status = 0
        state.length = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.updateUser = action.payload;
        state.length = action.payload.length
        state.error=null
      })
      .addCase(updateUserAsync.rejected, (state, action) => {
        state.loading = false;
        state.error=action.payload
        state.length = 0
      });
  },
});

export const { updateUserReset } = updateUserSlice.actions;

export const updateUserState = (state) => state.updateUserSlice;


export default updateUserSlice.reducer;
