import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import urls from "../../../app/requests/urls";
import { headerRequest } from "../../../app/requests/headerRequest";

const initialState = {
  loading: false,
  getOneSchool: [],
  error: null,
};

export const getOneSchoolAsync = createAsyncThunk("getOneSchool", async (school) => {
  const response = await axios.get(
    `${urls.localURL}/school/${school}/`,
    headerRequest()
  );
  return response.data;
});

export const getOneSchoolSlice = createSlice({
  name: "getOneSchool",
  initialState,
  reducers: {
    getOneSchoolReset: (state) => {
      state.loading = false;
      state.getOneSchool = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOneSchoolAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOneSchoolAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getOneSchool = action.payload;
      })
      .addCase(getOneSchoolAsync.rejected, (state) => {
        state.loading = false;
        state.getOneSchool = null;
        state.error = null;
      });
  },
});

export const getOneSchoolState = (state) => state.getOneSchoolSlice;

export const { getOneSchoolReset } = getOneSchoolSlice.actions;
export default getOneSchoolSlice.reducer;
