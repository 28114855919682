import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import urls from "../../../app/requests/urls";
import { headerRequest } from "../../../app/requests/headerRequest";

const initialState = {
  loading: false,
  getPaymentCourse: null,
  error: null,
};

export const getPaymentCourseAsync = createAsyncThunk("getPaymentCourse", async (id) => {
  const response = await axios.get(
    `${urls.localURL}/payment_course/${id}/`,
    headerRequest()
  );
  return response.data;
});

export const getPaymentCourseSlice = createSlice({
  name: "getPaymentCourse",
  initialState,
  reducers: {
    getPaymentCourseReset: (state) => {
      state.loading = false;
      state.getPaymentCourse = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentCourseAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPaymentCourseAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getPaymentCourse = action.payload;
      })
      .addCase(getPaymentCourseAsync.rejected, (state) => {
        state.loading = false;
        state.getPaymentCourse = null;
        state.error = null;
      });
  },
});

export const getPaymentCourseState = (state) => state.getPaymentCourseSlice;

export const { getPaymentCourseReset } = getPaymentCourseSlice.actions;
export default getPaymentCourseSlice.reducer;
