import { Suspense, lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import Homepage from "../features/Homepage/Homepage";
import Page404 from "../Common/Results/Page404";
import Auth from "../features/Auth/Auth";
import {
  activate,
  courses,
  forgot,
  home,
  login,
  logout,
  manage,
  manageAccount,
  oneCourse,
  oneEducation,
  oneLecture,
  oneSchool,
  page404,
  passwordReset,
  profile,
  schoolsEducation,
  signup,
  terms,
} from "./URL/RouteUrl";
import { Spin } from "antd";
import ProtectedRouteForLogin from "./ProtectedRoute/ProtectedRouteForLogin";
import ProtectedRouteForProfile from "./ProtectedRoute/ProtectedRouteForProfile";

const ProfileDetails = lazy(() =>
  import("../features/ProfileDetails/ProfileDetails")
);

const OneCourse = lazy(() =>
  import("../features/OneSchool/OneCourse/OneCourse")
);
const OneLecture = lazy(() =>
  import("../features/OneSchool/OneLecture/OneLecture")
);
const OneSchool = lazy(() => import("../features/OneSchool/OneSchool"));
const Educations = lazy(() => import("../features/Education/Educations"));
const SchoolsEducation = lazy(() =>
  import("../features/Education/SchoolsEducation/SchoolsEducation")
);

const OneSchoolLayout = lazy(() =>
  import("../features/OneSchool/OneSchoolLayout")
);
const Schools = lazy(() => import("../features/Schools/Schools"));
const Manage = lazy(() => import("../features/Manage/Manage"));
const Login = lazy(() => import("../features/Auth/Login/Login"));
const Registration = lazy(() =>
  import("../features/Auth/Registration/Registration")
);
const ForgotPassword = lazy(() =>
  import("../features/Auth/ForgotPassword/ForgotPassword")
);
const NewPassword = lazy(() =>
  import("../features/Auth/ForgotPassword/NewPassword")
);
const Logout = lazy(() => import("../features/Auth/Login/Logout"));

const ActivateAccount = lazy(() => import("../Common/Results/ActivateAccount"));
const Terms = lazy(() => import("../Common/Results/Terms"));

export const router = createBrowserRouter(
  [
    {
      path: home,
      errorElement: <Page404 />,
      element: (
        <ProtectedRouteForProfile>
          <Homepage />
        </ProtectedRouteForProfile>
      ),
      children: [
        {
          path: home,
          element: (
            <Suspense fallback={<Spin className="loading" />}>
              <Schools />
            </Suspense>
          ),
        },
        {
          path: profile,
          element: (
            <Suspense fallback={<Spin className="loading" />}>
              <ProfileDetails />
            </Suspense>
          ),
        },
        {
          path: manageAccount,
          element: (
            <Suspense fallback={<Spin className="loading" />}>
              <Manage />
            </Suspense>
          ),
        },
      ],
    },

    {
      path: logout,
      element: (
        <ProtectedRouteForProfile>
          <Suspense fallback={<Spin className="loading" />}>
            <Logout />
          </Suspense>
        </ProtectedRouteForProfile>
      ),
    },
    {
      path: page404,
      element: (
        <Page404 />
      ),
    },

    {
      path: manage,
      errorElement: <Page404 />,
      element: (
        <ProtectedRouteForLogin>
          <Suspense fallback={<Spin className="loading" />}>
            <Auth />
          </Suspense>
        </ProtectedRouteForLogin>
      ),
      children: [
        {
          path: signup,
          element: <Registration />,
        },
        {
          path: forgot,
          element: <ForgotPassword />,
        },

        {
          path: activate,
          element: <ActivateAccount />,
        },
        {
          path: terms,
          element: <Terms />,
        },
        {
          path: passwordReset,
          element: <NewPassword />,
        },
      ],
    },

    {
      path: oneSchool,
      errorElement: <Page404 />,
      element: (
        <ProtectedRouteForProfile>
          <Suspense fallback={<Spin className="loading" />}>
            <OneSchoolLayout />
          </Suspense>
        </ProtectedRouteForProfile>
      ),
      children: [
        {
          path: courses,
          element: <OneSchool />,
        },
        {
          path: oneCourse,
          element: <OneCourse />,
        },
      ],
    },

    {
      path: oneLecture,
      errorElement: <Page404 />,
      element: (
        <ProtectedRouteForProfile>
          <Suspense fallback={<Spin className="loading" />}>
            <OneLecture />
          </Suspense>
        </ProtectedRouteForProfile>
      ),
    },

    {
      path: login,
      element: (
        <ProtectedRouteForLogin>
          <Suspense fallback={<Spin className="loading" />}>
            <Login />
          </Suspense>
        </ProtectedRouteForLogin>
      ),
    },

    {
      path: oneEducation,
      element: (
        <ProtectedRouteForProfile>
          <Suspense fallback={<Spin className="loading" />}>
            <Educations />
          </Suspense>
        </ProtectedRouteForProfile>
      ),
    },

    {
      path: schoolsEducation,
      element: (
        <ProtectedRouteForProfile>
          <Suspense fallback={<Spin className="loading" />}>
            <SchoolsEducation />
          </Suspense>
        </ProtectedRouteForProfile>
      ),
    },
  ],
  {
    basename: "/curriculum",
  }
);
