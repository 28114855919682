import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  loading: false,
  getCourses: [],
  error: null,
};

export const getCoursesAsync = createAsyncThunk("getCourses", async (slug) => {
  const response = await axios.get(
    `${urls.localURL}/courses/?school__slug=${slug}&curriculum=true`,
    headerRequest()
  );
  return response.data;
});

export const getCoursesSlice = createSlice({
  name: "getCourses",
  initialState,
  reducers: {
    getCoursesReset: (state) => {
      state.loading = false;
      state.getCourses = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCoursesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCoursesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getCourses = action.payload;
      })
      .addCase(getCoursesAsync.rejected, (state) => {
        state.loading = false;
        state.getCourses = null;
        state.error = null;
      });
  },
});

export const getCoursesState = (state) => state.getCoursesSlice;

export const { getCoursesReset } = getCoursesSlice.actions;
export default getCoursesSlice.reducer;
