import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import urls from "../../../app/requests/urls";
import { headerRequest } from "../../../app/requests/headerRequest";

const initialState = {
  loading: false,
  postPaymentCourse: null,
  error: null,
};

export const postPaymentCourseAsync = createAsyncThunk(
  "postPaymentCourse",
  async (data, { rejectWithValue }) => {
    const { values, payment } = data;
    try {
      const response = await axios.post(
        `${urls.localURL}/payment_course/?issuer=${payment}`,
        values,
        headerRequest()
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const postPaymentCourseSlice = createSlice({
  name: "postPaymentCourse",
  initialState,
  reducers: {
    postPaymentCourseReset: (state) => {
      state.loading = false;
      state.postPaymentCourse = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postPaymentCourseAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(postPaymentCourseAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.postPaymentCourse = action.payload;
      })
      .addCase(postPaymentCourseAsync.rejected, (state) => {
        state.loading = false;
        state.postPaymentCourse = null;
        state.error = null;
      });
  },
});

export const postPaymentCourseState = (state) => state.postPaymentCourseSlice;

export const { postPaymentCourseReset } = postPaymentCourseSlice.actions;
export default postPaymentCourseSlice.reducer;
