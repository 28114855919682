import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  loading: false,
  deleteComment: false,
  error: null,
};

export const deleteCommentAsync = createAsyncThunk(
  "deleteComment",
  async (id) => {
    const response = await axios.delete(
      `${urls.localURL}/comments/${id}/`,
      headerRequest()
    );
    return response.data;
  }
);

export const deleteCommentSlice = createSlice({
  name: "deleteComment",
  initialState,
  reducers: {
    deleteCommentReset: (state) => {
      state.loading = false;
      state.deleteComment = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteCommentAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCommentAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteComment = true;
      })
      .addCase(deleteCommentAsync.rejected, (state) => {
        state.loading = false;
        state.deleteComment = false;
        state.error = null;
      });
  },
});

export const deleteCommentState = (state) => state.deleteCommentSlice;

export const { deleteCommentReset } = deleteCommentSlice.actions;
export default deleteCommentSlice.reducer;
