import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  updateProfile: null,
  error: null,
  length: 0,
  loading: false,
};

export const updateProfileAsync = createAsyncThunk(
  "account/updateProfileSlice",
  async (data, { rejectWithValue }) => {
    const { id, values, remove = "" } = data
    try {
      const response = await axios.put(`${urls.localURL}/profile/${id}/?remove=${remove}`, values, headerRequest())
    return response.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
    
  }
);

export const updateProfileSlice = createSlice({
  name: "updateProfileSlice",
  initialState,
  reducers: {
    updateProfileReset: (state) => {
        state.updateProfile=null
        state.error=null
        state.status = 0
        state.length = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProfileAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProfileAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.updateProfile = action.payload;
        state.length = action.payload.length
      })
      .addCase(updateProfileAsync.rejected, (state, action) => {
        state.loading = false;
        state.error=action.payload
        state.length = 0
      });
  },
});

export const { updateProfileReset } = updateProfileSlice.actions;

export const updateProfileState = (state) => state.updateProfileSlice;


export default updateProfileSlice.reducer;
