import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import urls from "../../../../app/requests/urls";
import { headerRequest } from "../../../../app/requests/headerRequest";

const initialState = {
  postRegistration: null,
  error: null,
  loading: false,
};

export const postRegistrationAsync = createAsyncThunk(
  "auth/postRegistrationSlice",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${urls.localURL}/rest-auth/registration/`, values, headerRequest())
    return response.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
    
  }
);

export const postRegistrationSlice = createSlice({
  name: "postRegistrationSlice",
  initialState,
  reducers: {
    postRegistrationReset: (state) => {
        state.postRegistration=null
        state.error=null
        state.status = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postRegistrationAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(postRegistrationAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.postRegistration = action.payload;
        state.error=null
      })
      .addCase(postRegistrationAsync.rejected, (state, action) => {
        state.loading = false;
        state.postRegistration=null
        state.error=action.payload
      });
  },
});

export const { postRegistrationReset } = postRegistrationSlice.actions;

export const postRegistrationState = (state) => state.postRegistrationSlice;


export default postRegistrationSlice.reducer;
