export const headerRequest = () => {
  const token = localStorage.getItem("token");
  const headers = {
    "X-CSRFToken": window.csrftoken,
    Authorization: token ? `Token ${token}` : " ",
  };

  return {
    headers,
  };
};
