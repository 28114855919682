import React, { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserAsync,
  getUserState,
} from "../../features/ProfileDetails/Account/Slice/getUserSlice";
import { updateUserState } from "../../features/ProfileDetails/Account/Slice/updateUserSlice";
import { Spin } from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import { activate, login, manage } from "../URL/RouteUrl";
import { postAttendanceState } from "../../features/OneSchool/Attendance/Slice/postAttendanceSlice";

const ProtectedRouteForProfile = ({ children }) => {
  const dispatch = useDispatch();
  const { status, getUser } = useSelector(getUserState);
  const { postAttendance } = useSelector(postAttendanceState);
  const { updateUser } = useSelector(updateUserState);
  const navigate = useNavigate();
  React.useEffect(() => {
    dispatch(getUserAsync());
  }, [dispatch, postAttendance, updateUser]);

  React.useEffect(() => {
    if (status === 401) {
      localStorage.removeItem("token");
      window.location.href = `/curriculum/${login}`;
    }
  }, [status, navigate]);
  return (
    <Suspense fallback={<Spin className="loading" />}>
      {getUser === null ? (
        <Spin style={{ margin: "50%" }} />
      ) : getUser?.verify_email ? (
        children
      ) : (
        <Navigate to={`${manage}/${activate}`} />
      )}
    </Suspense>
  );
};
export default ProtectedRouteForProfile;
