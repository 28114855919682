import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  oneCourse: null,
  drawer: false,
  loading: false,
  accessCoupon: null,
  error: null,
};

export const AccessCouponAsync = createAsyncThunk(
  "AccessCoupon",
  async (data,  { rejectWithValue }) => {
    try {
    const response = await axios.post(
      `${urls.localURL}/coupon/?action=access`,
      data,
      headerRequest()
    );
    return response.data;
} catch (err) {
    if (!err.response) {
      throw err
    }
    return rejectWithValue(err.response.data)
  }
  }
);

export const accessCouponSlice = createSlice({
  name: "accessCoupon",
  initialState,
  reducers: {
    selecteCourse: (state, action) => {
      state.oneCourse = action.payload;
      state.drawer = true;
    },
    resetselecteCourse: (state) => {
      state.oneCourse = null;
      state.drawer = false;
      state.error = null;
    },

    accessCouponReset: (state) => {
      state.loading = false;
      state.accessCoupon = null;
      state.error = null;
      state.oneCourse = null;
      state.drawer = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AccessCouponAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(AccessCouponAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.accessCoupon = action.payload;
      })
      .addCase(AccessCouponAsync.rejected, (state, action) => {
        state.loading = false;
        state.accessCoupon = null;
        state.error = action.payload;
      });
  },
});

export const accessCouponState = (state) => state.accessCouponSlice;

export const { accessCouponReset, selecteCourse, resetselecteCourse } =
  accessCouponSlice.actions;
export default accessCouponSlice.reducer;
