import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  loading: false,
  postProgress: null,
  error: null,
};

export const postProgressAsync = createAsyncThunk(
  "postProgress",
  async (data) => {
    const response = await axios.post(
      `${urls.localURL}/progress-course/`,
      data,
      headerRequest()
    );
    return response.data;
  }
);

export const postProgressSlice = createSlice({
  name: "postProgress",
  initialState,
  reducers: {
    postProgressReset: (state) => {
      state.loading = false;
      state.postProgress = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postProgressAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(postProgressAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.postProgress = action.payload;
      })
      .addCase(postProgressAsync.rejected, (state) => {
        state.loading = false;
        state.postProgress = null;
        state.error = null;
      });
  },
});

export const postProgressState = (state) => state.postProgressSlice;

export const { postProgressReset } = postProgressSlice.actions;
export default postProgressSlice.reducer;
