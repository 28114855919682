import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import urls from "../../../app/requests/urls";
import { headerRequest } from "../../../app/requests/headerRequest";

const initialState = {
  loading: false,
  getPaymentMethods: null,
  error: null,
};

export const getPaymentMethodsAsync = createAsyncThunk("getPaymentMethods", async () => {
  const response = await axios.get(
    `${urls.localURL}/payment_methods/`,
    headerRequest()
  );
  return response.data;
});

export const getPaymentMethodsSlice = createSlice({
  name: "getPaymentMethods",
  initialState,
  reducers: {
    getPaymentMethodsReset: (state) => {
      state.loading = false;
      state.getPaymentMethods = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentMethodsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPaymentMethodsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getPaymentMethods = action.payload?.[0];
      })
      .addCase(getPaymentMethodsAsync.rejected, (state) => {
        state.loading = false;
        state.getPaymentMethods = null;
        state.error = null;
      });
  },
});

export const getPaymentMethodsState = (state) => state.getPaymentMethodsSlice;

export const { getPaymentMethodsReset } = getPaymentMethodsSlice.actions;
export default getPaymentMethodsSlice.reducer;
