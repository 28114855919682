import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  updateSocialMedia: null,
  error: null,
  loading: false,
};

export const updateSocialMediaAsync = createAsyncThunk(
  "account/updateSocialMediaSlice",
  async (data, { rejectWithValue }) => {
    const { id, values } = data
    try {
      const response = await axios.put(`${urls.localURL}/social-profile/${id}/`, values, headerRequest())
    return response.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
    
  }
);

export const updateSocialMediaSlice = createSlice({
  name: "updateSocialMediaSlice",
  initialState,
  reducers: {
    updateSocialMediaReset: (state) => {
        state.updateSocialMedia=null
        state.error=null
        state.status = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateSocialMediaAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSocialMediaAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSocialMedia = action.payload;
        state.error=null
      })
      .addCase(updateSocialMediaAsync.rejected, (state, action) => {
        state.loading = false;
        state.error=action.payload
      });
  },
});

export const { updateSocialMediaReset } = updateSocialMediaSlice.actions;

export const updateSocialMediaState = (state) => state.updateSocialMediaSlice;


export default updateSocialMediaSlice.reducer;
