import { configureStore } from "@reduxjs/toolkit";
import getSchoolSlice from "../features/Schools/Slice/getSchoolSlice";
import getProfileSlice from "../features/ProfileDetails/Account/Slice/getProfileSlice";
import getUserSlice from "../features/ProfileDetails/Account/Slice/getUserSlice";
import getSocialMediaSlice from "../features/ProfileDetails/SocialMedia/Slice/getSocialMediaSlice";
import getSocialIconSlice from "../features/ProfileDetails/SocialMedia/Slice/getSocialIconSlice";
import updateUserSlice from "../features/ProfileDetails/Account/Slice/updateUserSlice";
import updateProfileSlice from "../features/ProfileDetails/Account/Slice/updateProfileSlice";
import updateSocialMediaSlice from "../features/ProfileDetails/SocialMedia/Slice/updateSocialMediaSlice";
import postSocialMediaSlice from "../features/ProfileDetails/SocialMedia/Slice/postSocialMediaSlice";
import postChangePasswordSlice from "../features/Manage/Slice/postChangePasswordSlice";
import deleteUserSlice from "../features/Manage/Slice/deleteUserSlice";
import postLogoutSlice from "../features/Auth/Login/Slice/postLogoutSlice";
import postLoginSlice from "../features/Auth/Login/Slice/postLoginSlice";
import postForgotPasswordSlice from "../features/Auth/ForgotPassword/Slice/postForgotPasswordSlice";
import postConfirmPasswordSlice from "../features/Auth/ForgotPassword/Slice/postConfirmPasswordSlice";
import postRegistrationSlice from "../features/Auth/Registration/Slice/postRegistrationSlice";
import getCoursesSlice from "../features/OneSchool/Courses/Slice/getCoursesSlice";
import getOneCourseSlice from "../features/OneSchool/OneCourse/Slice/getOneCourseSlice";
import getGroupLecturesSlice from "../features/OneSchool/OneCourse/Slice/getGroupLecturesSlice";
import getOneLectureSlice from "../features/OneSchool/OneLecture/Slice/getOneLectureSlice";
import getCommentsSlice from "../features/OneSchool/OneLecture/Slice/getCommentsSlice";
import postCommentSlice from "../features/OneSchool/OneLecture/Slice/postCommentSlice";
import deleteCommentSlice from "../features/OneSchool/OneLecture/Slice/deleteCommentSlice";
import postProgressSlice from "../features/OneSchool/OneLecture/Slice/postProgressSlice";
import getRateSlice from "../features/OneSchool/Components/Slice/getRateSlice";
import postRateSlice from "../features/OneSchool/Components/Slice/postRateSlice";
import updateRateSlice from "../features/OneSchool/Components/Slice/updateRateSlice";
import accessCouponSlice from "../features/OneSchool/Courses/Slice/accessCouponSlice";
import getOneSchoolSlice from "../features/OneSchool/Slice/getOneSchoolSlice";
import getPresentationsSlice from "../features/OneSchool/Presentation/Slice/getPresentationsSlice";
import getCalendarSlice from "../features/OneSchool/Calendar/Slice/getCalendarSlice";
import getAttendanceSlice from "../features/OneSchool/Attendance/Slice/getAttendanceSlice";
import postAttendanceSlice from "../features/OneSchool/Attendance/Slice/postAttendanceSlice";
import getGroupsSlice from "../features/OneSchool/Students/Slice/getGroupsSlice";
import getOneEducationSlice from "../features/Education/Slice/getOneEducationSlice";
import getPaymentMethodsSlice from "../features/OneSchool/Slice/getPaymentMethodsSlice";
import getPaymentCourseSlice from "../features/OneSchool/Slice/getPaymentCourseSlice";
import postPaymentCourseSlice from "../features/OneSchool/Slice/postPaymentCourseSlice";

import themeSlice from "./Slice/themeSlice";
export const store = configureStore({
  reducer: {
    getSchoolSlice,
    getProfileSlice,
    getUserSlice,
    getSocialMediaSlice,
    getSocialIconSlice,
    updateUserSlice,
    updateProfileSlice,
    updateSocialMediaSlice,
    postSocialMediaSlice,
    postChangePasswordSlice,
    deleteUserSlice,
    postLogoutSlice,
    postLoginSlice,
    postForgotPasswordSlice,
    postConfirmPasswordSlice,
    postRegistrationSlice,
    themeSlice,
    getCoursesSlice,
    getOneCourseSlice,
    getGroupLecturesSlice,
    getOneLectureSlice,
    getCommentsSlice,
    postCommentSlice,
    deleteCommentSlice,
    postProgressSlice,
    getRateSlice,
    postRateSlice,
    updateRateSlice,
    accessCouponSlice,
    getOneSchoolSlice,
    getPresentationsSlice,
    getCalendarSlice,
    getAttendanceSlice,
    postAttendanceSlice,
    getGroupsSlice,
    getOneEducationSlice,
    getPaymentMethodsSlice,
    getPaymentCourseSlice,
    postPaymentCourseSlice,
  },
});
