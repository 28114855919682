import React from 'react'
import moment from 'moment'
import { Layout } from "antd"
const Footer = () => {
  return (
    <Layout.Footer
        style={{
          textAlign: "center",
        }}
      >
        Matrix Master © {moment().format('YYYY')} Created by 
            <a href='https://codematrix.nl'> Code Matrix Zone</a>
      </Layout.Footer>
  )
}

export default Footer