import { createSlice } from "@reduxjs/toolkit";


const theme = localStorage.getItem("theme") === "true" || false
const initialState = {
  isDarkMode: theme,
};

export const themeSlice = createSlice({
  name: "app/theme",
  initialState,
  reducers: {
    changeTheme: (state) => {
      state.isDarkMode = !state.isDarkMode;
      localStorage.setItem("theme", state.isDarkMode)
      
    },
  },
});

export const { changeTheme } = themeSlice.actions;

export const themeState = (state) => state.themeSlice;

export default themeSlice.reducer;
