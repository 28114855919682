import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import urls from "../../../../app/requests/urls";
import { headerRequest } from "../../../../app/requests/headerRequest";

const initialState = {
  loading: false,
  getGroups: [],
  error: null,
};

export const getGroupsAsync = createAsyncThunk("getGroups", async (data) => {
  const { id, student } = data
  const response = await axios.get(
    `${urls.localURL}/group/?school=${id}&student__id=${student}&action=curriculum`,
    headerRequest()
  );
  return response.data;
});

export const getGroupsSlice = createSlice({
  name: "getGroups",
  initialState,
  reducers: {
    getGroupsReset: (state) => {
      state.loading = false;
      state.getGroups = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGroupsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGroupsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getGroups = action.payload;
      })
      .addCase(getGroupsAsync.rejected, (state) => {
        state.loading = false;
        state.getGroups = null;
        state.error = null;
      });
  },
});

export const getGroupsState = (state) => state.getGroupsSlice;

export const { getGroupsReset } = getGroupsSlice.actions;
export default getGroupsSlice.reducer;
