import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  loading: false,
  updateRate: null,
  error: null,
};

export const updateRateAsync = createAsyncThunk(
  "updateRate",
  async (data) => {
    const response = await axios.put(
      `${urls.localURL}/review/${data.id}/`,
      data,
      headerRequest()
    );
    return response.data;
  }
);

export const updateRateSlice = createSlice({
  name: "updateRate",
  initialState,
  reducers: {
    updateRateReset: (state) => {
      state.loading = false;
      state.updateRate = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateRateAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateRateAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.updateRate = action.payload;
      })
      .addCase(updateRateAsync.rejected, (state) => {
        state.loading = false;
        state.updateRate = null;
        state.error = null;
      });
  },
});

export const updateRateState = (state) => state.updateRateSlice;

export const { updateRateReset } = updateRateSlice.actions;
export default updateRateSlice.reducer;
