import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  loading: false,
  getComments: [],
  error: null,
};

export const getCommentsAsync = createAsyncThunk("getComments", async (lecture) => {
  const response = await axios.get(
    `${urls.localURL}/comments/?lecture=${lecture}`,
    headerRequest()
  );
  return response.data;
});

export const getCommentsSlice = createSlice({
  name: "getComments",
  initialState,
  reducers: {
    getCommentsReset: (state) => {
      state.loading = false;
      state.getComments = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommentsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCommentsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getComments = action.payload;
      })
      .addCase(getCommentsAsync.rejected, (state) => {
        state.loading = false;
        state.getComments = null;
        state.error = null;
      });
  },
});

export const getCommentsState = (state) => state.getCommentsSlice;

export const { getCommentsReset } = getCommentsSlice.actions;
export default getCommentsSlice.reducer;
