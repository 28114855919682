import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { headerRequest } from "../../../../app/requests/headerRequest";
import urls from "../../../../app/requests/urls";

const initialState = {
  getSocialIcon: null,
  error: null,
  length: 0,
  loading: false,
};

export const getSocialIconAsync = createAsyncThunk(
  "account/getSocialIconSlice",
  async () => {
    const response = await axios.get(`${urls.localURL}/social-icon/`, headerRequest())
    return response.data;
  }
);

export const getSocialIconSlice = createSlice({
  name: "getSocialIconSlice",
  initialState,
  reducers: {
    getSocialIconReset: (state) => {
        state.getSocialIcon=null
        state.error=null
        state.status = 0
        state.length = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSocialIconAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSocialIconAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getSocialIcon = action.payload;
        state.length = action.payload.length
      })
      .addCase(getSocialIconAsync.rejected, (state) => {
        state.loading = false;
        state.getSocialIcon=null
        state.error=null
        state.length = 0
      });
  },
});

export const { getSocialIconReset } = getSocialIconSlice.actions;

export const getSocialIconState = (state) => state.getSocialIconSlice;


export default getSocialIconSlice.reducer;
